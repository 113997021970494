export const tableOption = {
  searchMenuSpan: 6,
  columnBtn: false,
  border: true,
  selection: true,
  index: false,
  indexLabel: '序号',
  stripe: true,
  menuAlign: 'center',
  menuWidth: 350,
  align: 'center',
  refreshBtn: true,
  searchSize: 'mini',
  addBtn: false,
  editBtn: false,
  delBtn: false,
  viewBtn: false,
  props: {
    label: 'label',
    value: 'value'
  },
  column: [{
    label: '计划编号',
    prop: 'planId'
  }, {
    label: '申请周期',
    prop: 'projectCycle'
  }, {
    width: 150,
    label: '状态',
    prop: 'projectStatus',
    search: true,
    type: 'select',
    dicData: [
      {
        label: '待发布',
        value: 0
      }, {
        label: '发布',
        value: 1
      }
    ]
  }, {
    label: '名称',
    prop: 'detailName'
  }, {
    label: '内容',
    prop: 'detailContent'
  }]
}
